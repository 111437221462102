div.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  transform: scale(0.8); 
  transform-origin: center;
}

div.login {
  width: 652px;
  height: 663px;

  background: #FEFEFE;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img.login {
  max-width: 50%;
  height: auto;
  margin-bottom: 40px;
}

div.google,
div.kakao {
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

button.google,
button.kakao {
  width: 100%;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 35px;
  box-sizing: border-box;
}

button.google:hover,
button.kakao:hover {
  background-color: #FEFEFE;
}

img.google,
img.kakao {
  max-width: 100%;
  height: auto;
}
