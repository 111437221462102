.input_container {
  position: fixed;
  width: calc(125% - 780px);
  height: 125%;
  left: 67px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transform: scale(0.8);
  transform-origin: top left; 
}

.input_company {
  position: relative;
  left: 40px;
  top: 40px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #2248B8;
}

.input_position {
  position: relative;
  left: 40px;
  top: 12px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #0D0D0D;
}

.input_line {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 12px;
}

.input_header {
  position: relative;
  top: 24px;
  padding: 0px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header_intro_wrapper {
  width: 70%;
  background-color: transparent;
}

.header_intro {
  position: relative;
  width: 70%;
  border: none;
  background-color: transparent;
  
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #2248B8;
}

.header_intro_wrapper::before {
  content: '*';
  color: #FF0000;
  margin-right: 5px;
}

.header_intro::placeholder {
  background-color: transparent;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #2248B8;
}

.header_caution {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #FF0000;
}

.input_menu {
  position: relative;
  left: 40px;
  top: 24px;
  padding-bottom: 80px;
}

.menu_title {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0D0D0D;
}

.menu_textarea {
  position: relative;
  width: 88%;
  top: 16px;
  border: none;
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  resize: none;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  background-color: transparent;
  color: #0D0D0D;
}

.input_footer {
  position: relative;
  bottom: 0px;
  padding: 0px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer_number {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.number_intro{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2248B8;
  margin-right: 12px;
}

.number_intro::before {
  content: '*';
  color: #FF0000;
  margin-right: 5px;
}

.number_current {
  margin-right: 8px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

.number_limit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.limit_input {
  box-sizing: border-box;
  width: 59px;
  height: 32px;
  border: 1px solid #2248B8;
  border-radius: 50px;
  
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #2248B8;
}

.footer_button {
  position: relative;
  width: 150px;
  height: 44px;
  text-align: center;
  background: linear-gradient(137.46deg, #2248B8 14.95%, #7E7DDB 57.81%, #DEB5FF 100%);
  border-radius: 68px;
}

.footer_button_text {
  width: 100%;
  height: 100%;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FEFEFE;
}