.side_container {
  position: fixed;
  width: 84px;
  height: 125%;
  background: #FEFEFE;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0px 32px 32px 0px;
  transition: width 0.2s ease;
  display: flex;
  flex-direction: column;
  z-index: 3;
  transform: scale(0.8); 
  transform-origin: top left;
}

.side_container.hovered {
  width: 220px;
}

.logo {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 64px;
}

.recruitment_container {
  position: relative;
  left: 32px;
  top: 130px; 
}

.script_container {
  position: relative;
  left: 32px;
  top: 170px;
  padding-bottom: 400px;
  margin-right: 32px;
  flex-grow: 1;
}

button {
  border: none;
  background-color: transparent;
}

.chat_container {
  position: relative;
  left: 32px;
  margin-right: 32px;
  bottom: 100px;
}

.open_container {
  position: relative;
  left: 32px;
  margin-right: 32px;
  bottom: 80px;
}

.signout_container {
  position: relative;
  left: 32px;
  margin-right: 32px;
  bottom: 60px;
}
