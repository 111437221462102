.container {
  width: 100%;
  height: 125vh;
  background: #FCFCFC;
  display: flex;
  max-width: 1440px;
  flex-direction: row;
  transform: scale(0.8);
  transform-origin: top left;
}

.menu_container,
.main_container {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.menu_container {
  width: 280px;
  height: 100%;
  background: #FEFEFE;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0px 32px 32px 0px;
  position: fixed;
}

img.logo {
  max-width: 50%;
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

.line {
  width: 90%;
  border: 0.5px solid #676767;
}

img.sample1 {
  max-width: 70%;
  height: auto;
  padding-top: 56px;
}

img.sample2 {
  max-width: 80%;
  height: auto;
  padding-top: 8px;
}

img.sample3 {
  max-width: 80%;
  height: auto;
  padding-top: 8px;
}

.bottom_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: 72px;
  margin-bottom: 48px;
  gap: 10px;
}

button.chat,
button.open,
button.sign {
  align-self: start;
  border: none;
  background-color: transparent;
}

button.chat:hover,
button.open:hover,
button.sign:hover {
  background-color: #eeeeeea6;
}

.main_container {
  width: calc(100% - 280px);
  padding-left: 280px;
  align-items: start;
  flex-wrap: wrap;
  flex-direction: row;
}

.search_container {
  width: 540px;
  height: 50px;
  margin-top: 100px;
  margin-left: 120px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  background-color: #FEFEFE;
  box-sizing: border-box;
  border: 1px solid #2248B8;
  border-radius: 24px;
  align-items: center;
}

input.search {
  width: 85%;
  height: 70%;
  font-size: 16px;
  border: none;
  background-color: transparent;
  flex-shrink: 0;
}
input:focus {outline:none;}

button.search {
  width: 28px;
  height: 29px;
  margin-left: 10px;
  border: none;
  background-color: transparent;
  flex-shrink: 0;
}

.card_container {
  width: 100%;
  height: calc(100% - 182px);
  margin-top: 32px;
  margin-left: 120px;
  display: flex;
  flex-flow: row wrap;
  gap: 0px 24px;
}

.card {
  width: 176px;
  height: 256px;
  display: flex;
  flex-direction: column;
}

button.add_card {
  width: 100%;
  height: 100%;
  background: #FEFEFE;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 16px;
}

button.card_list {
  width: 100%;
  height: 100%;
  background: #FEFEFE;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 16px;
}

button.card_list_pin {
  position: absolute;
  margin-left: 16px;
  margin-top: 16px;
  background: transparent;
  border: none;
}

button.card_list_delete {
  position: absolute;
  margin-left: 136px;
  margin-top: 16px;
  background: transparent;
  border: none;
}

span.card_list_company {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #2248B8;
}

span.card_list_position {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7A7A7A;
}

span.card_list_questions {
  font-family: 'NanumSquare';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0D0D0D;
}
