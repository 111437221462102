.feedback_container {
  position: fixed;
  right: 0px;
  width: 696px;
  height: 125%;
  background: #FEFEFE;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px 0px 0px 16px;
  z-index: 2;
  transform: scale(0.8);
  transform-origin: top right; 
}

.feedback_title {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #2248B8;
}

.category_container_before_selection {
  position: absolute;
  width: 180px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  top: 120px;

  z-index: 1;
  background-color: #FEFEFE;
  box-sizing: border-box;
  border: 1px solid #2248B8;
  border-radius: 24px;
}

button.category_before_selection {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

p.category_before_selection {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2248B8;
}

.category_container_after_selection {
  position: absolute;
  width: 180px;
  height: 80px;
  left: 50%;
  transform: translateX(-50%);
  top: 120px;

  z-index: 1;
  background-color: #FEFEFE;
  box-sizing: border-box;
  border: 1px solid #2248B8;
  border-radius: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button.category_after_selection {
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

p.category_after_selection {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0D0D0D;
}

.feedback_content_container {
  position: relative;
  width: 82%;
  height: 100%;
  max-width: 90%;
  top: 40px;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #0D0D0D;
}

.sub_category_container_before_selection {
  position: relative;
  width: 150px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  top: 24px;
  background-color: #FEFEFE;
  box-sizing: border-box;
  border: 1px solid #2248B8;
  border-radius: 24px;
}

button.sub_category_before_selection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

p.sub_category_before_selection {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2248B8;
}

.sub_category_container_after_selection {
  position: relative;
  width: 150px;
  height: 80px;
  left: 50%;
  transform: translateX(-50%);
  top: 24px;

  background-color: #FEFEFE;
  box-sizing: border-box;
  border: 1px solid #2248B8;
  border-radius: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button.sub_category_after_selection {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

p.sub_category_after_selection {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0D0D0D;
}

.feedback_content {
  position: relative;
  height: auto;
  box-sizing: border-box;
  border: 1px solid #2248B8;
  border-radius: 32px;
  
  display: flex;
  flex-direction: column;

  padding: 0px 24px;
  margin-top: 12px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

.feedback_content_intro {
  position: relative;
  left: 24px;
  top: 12px;
  width: 100%;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
}

.feedback_textarea {
  position: relative;
  width: 100%;
  height: 160px;
  top: 16px;
  padding: 20px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #0D0D0D;

  box-sizing: border-box;
  border: 1px solid #2248B8;
  border-radius: 32px;
  outline: none;
  overflow-y: auto;
  resize: none;
}

.footer_button {
  position: relative;
  width: 150px;
  height: 44px;
  text-align: center;
  background: linear-gradient(137.46deg, #2248B8 14.95%, #7E7DDB 57.81%, #DEB5FF 100%);
  border-radius: 68px;
}

.footer_button_text {
  width: 100%;
  height: 100%;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FEFEFE;
}

.feedback_steptext {
  position: relative;
  width: 100%;
  height: 16px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #0D0D0D;
}
