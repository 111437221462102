.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.modal_content {
  width: 616px;
  height: 760px;
  background-color: white;
  padding: 12px;
  border-radius: 10px;

  box-sizing: border-box;
  background: #FEFEFE;
  border: 1px solid #2248B8;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  transform: scale(0.8); 
  transform-origin: center;
}

.modal_title {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: bolder;
  font-size: 24px;
  text-align: center;
  color: #2248B8;
}

.modal_header {
  position: relative;
  top: 8px;
  left: 48px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0D0D0D;
}

.modal_input {
  position: relative;
  width: 80%;
  left: 48px;
  
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #0D0D0D;

  border: none;
}

.modal_textarea {
  position: relative;
  width: 80%;
  left: 48px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #0D0D0D;

  border: none;
  outline: none;
  overflow-y: auto;
  resize: none;
}

.modal_caution {
  position: relative;
  width: 500px;
  height: 10px;
  bottom: -64px;
  left: 48px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.modal_button {
  position: relative;
  left: 70%;
  top: 20px;
  width: 150px;
  height: 44px;
  text-align: center;
  background: linear-gradient(137.46deg, #2248B8 14.95%, #7E7DDB 57.81%, #DEB5FF 100%);
  border-radius: 68px;
}

.modal_button_text {
  width: 100%;
  height: 100%;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FEFEFE;
}